<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Manual Classification System (Class 1)"
          size="large"
        />

        <p class="font-bold">
          The ET Users’ Group Test Methods Matrix™ has a section on the UN
          Manual for Testing and Criteria. This section is outlined based on the
          test series listed in the UN Manual and follows the same format as
          discussed above. The UN Manual tests are included in the database
          since many of the in-process tests use similar or the same test
          parameters. The
          <a
            style="color:#ef9a3d;"
            target="_blank"
            href="http://www.igus-experts.org/"
            >International Group of Experts on the Explosion Risks of Unstable
            Substances (IGUS)</a
          >
          has stewardship for any additions or modifications to this section.
        </p>

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2">
            <h2 class="text-2xl font-bold">
              UN MTC Classification System for Class 1 Substances and Articles
            </h2>
            <img src="@/assets/Figure-10-2-min.svg" />
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-2xl font-bold">
              Continued UN MTC Classification System for Class 1 Substances and
              Articles
            </h2>
            <img src="@/assets/Figure-10-3-min.svg" />
          </div>
          <div class="w-full">
            <p class="font-bold">
              Please contribute to the Test Method Matrix by adding to the
              database by: (1) submitting comments at the bottom of each page,
              (2) submitting new content or modifications on the
              <!-- /content-submittal-form -->
              <router-link to="/contact-us" style="color:#ef9a3d;"
                >Content Submittal Form</router-link
              >, (3) sending us comments via the
              <router-link style="color:#ef9a3d;" to="/contact-us"
                >Contact Us</router-link
              >
              page or (4) giving us a call at 801-567-0456.
            </p>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
